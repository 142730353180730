// src/components/trainingDue/TrainingDueInfo.jsx
import React from 'react';
import { Box, Typography, Button, Card, Chip } from '@mui/material';

export default function TrainingDueInfo({ trainingDueRecord, onViewEnglish, onViewSpanish }) {
    const { status, date, courseName } = trainingDueRecord;

    return (
        <Card sx={{ mb: 4, p: 3, backgroundColor: 'fff', borderRadius: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {'Training ' + courseName || 'TrainingDue Detail'}
                    </Typography>

                    {status && (
                        <Chip
                            label={`Status: ${status}`}
                            color={status === 'process' ? 'warning' : 'default'}
                            sx={{ mb: 1, fontWeight: 'bold' }}
                        />
                    )}

                    <Typography variant="subtitle1">
                        <strong>Date:</strong>{' '}
                        {date
                            ? new Date(date).toLocaleDateString('en-US')
                            : 'N/A'
                        }
                    </Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start">
                    <Button variant="contained" onClick={onViewEnglish} color="primary">
                        View English Text
                    </Button>
                    <Button variant="contained" onClick={onViewSpanish} color="primary">
                        View Spanish Text
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}

// src/components/trainingDue/TrainingDueTextModal.jsx
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

export default function TrainingDueTextModal({ open, onClose, textContent }) {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                maxWidth: '600px',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                p: 3,
                boxShadow: 24,
                borderRadius: '8px',
            }}>
                <Typography variant="h6" gutterBottom>
                    Training Text
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                    {textContent || 'No text found.'}
                </Typography>
                <Button onClick={onClose} sx={{ mt: 2 }} fullWidth variant="outlined">
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

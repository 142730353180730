// src/services/ftFormService.js
import { initializeDB } from '../config/db';
import {FT_FORM_STORE, STATUS_SEND} from "../config/constants";

/**
 * Получить все записи из хранилища ftForm (IndexedDB).
 */
export async function getAllFtForms() {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readonly');
    const store = tx.objectStore(FT_FORM_STORE);

    const allRecords = await store.getAll();
    await tx.done;
    return allRecords;
}

/**
 * Создать/обновить запись в ftForm.
 * Возвращает id сохранённой записи.
 */
export async function saveFtForm(formData) {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readwrite');
    const store = tx.objectStore(FT_FORM_STORE);

    // Если у formData нет id, autoIncrement сам присвоит.
    const newId = await store.put(formData);
    await tx.done;
    return newId;
}

/**
 * Помечаем запись как SEND (status='send') в ftForm.
 */
export async function markFtFormSend(id) {
    const db = await initializeDB();
    const tx = db.transaction(FT_FORM_STORE, 'readwrite');
    const store = tx.objectStore(FT_FORM_STORE);

    const record = await store.get(id);
    if (record) {
        record.status = STATUS_SEND;
        await store.put(record);
    }
    await tx.done;
}

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CreditCard from "@mui/icons-material/CreditCard";
import DocumentIcon from "@mui/icons-material/Description";
import NotesIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import MainPageIcon from "@mui/icons-material/Home";
import TrainingIcon from "@mui/icons-material/School";
import React from "react";

export const MAIN_MENU_ITEMS = [
    {
        label: 'Main',
        icon: <MainPageIcon fontSize="small" />,
        link: '/main',
    },
    {
        label: 'Administration',
        icon: <AdminPanelSettingsIcon fontSize="small" />,
        subMenu: [
            {
                label: 'Synchronization',
                link: '/admin/synchronization',
            },
            {
                label: 'Weekly Roster',
                link: '/admin/weekly-employee',
            },
        ],
    },
    {
        label: 'TBT',
        icon: <DocumentIcon fontSize="small" />,
        subMenu: [
            { label: 'TBT', link: '/tbt' },
            { label: 'Create new TBT', link: '/tbt/add' },
        ],
    },
    {
        label: 'Training',
        icon: <TrainingIcon fontSize="small" />,
        link: '/training-due',
    },
    {
        label: 'JSA',
        icon: <WorkIcon fontSize="small" />,
        subMenu: [
            { label: 'Create New Form', link: '/jsa/new' },
            { label: 'Saved Forms', link: '/jsa/saved' },
            { label: 'Submitted Forms', link: '/jsa/submitted' },
        ],
    },
    {
        label: 'CC',
        icon: <CreditCard fontSize="small" />,
        link: '/cc',
    },
    {
        label: 'PO',
        icon: <NotesIcon fontSize="small" />,
        link: '/po',
    },
];
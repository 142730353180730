// src/components/trainingDue/TrainingDue.jsx

import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { useTrainingDue } from '../../../hooks/useTrainingDue';
import { formatUSDateNoTime } from "../../../utils/utils";

export default function TrainingDue() {
    const trainingDueList = useTrainingDue();
    const [isNarrow, setIsNarrow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkWidth = () => {
            setIsNarrow(window.innerWidth < 900);
        };
        window.addEventListener('resize', checkWidth);
        checkWidth();
        return () => window.removeEventListener('resize', checkWidth);
    }, []);

    const safeData = Array.isArray(trainingDueList)
        ? trainingDueList.filter(item => item && item.id != null)
        : [];

    const baseColumns = [
        { field: 'course', headerName: 'Training Course', flex: 2 },
        { field: 'fullName', headerName: 'Employee name', flex: 2 },
        { field: 'division', headerName: 'Division', flex: 1 },
        {
            field: 'dateDue',
            headerName: 'Training Due',
            flex: 1,
            renderCell: (params) => {
                return formatUSDateNoTime(params.value);
            },
        },
    ];

    const columns = isNarrow ? baseColumns : baseColumns;

    const handleRowClick = (params) => {
        const row = params.row;
        const course = row.course || '';

        // Check course name
        const isMep = course.includes('MEWPS - aerial/platform lifts');
        const isForklift = course.includes('Forklift');

        if (isMep) {
            //fo to MEP form type
            navigate('/training-due/mep', {
                state: { trainingDueId: row.id }
            });
        } else if (isForklift) {
           // go FT form type
            navigate('/training-due/ft', {
                state: { trainingDueId: row.id }
            });
        } else {
            //go to training form
            navigate('/training-due/default', {
                state: { trainingDueId: row.id }
            });
        }
    }

    return (
        <Box sx={{ p: 4 }}>
            <Paper elevation={3} sx={{ p: 2, width: '90%', margin: 'auto' }}>
                <Link to="/">
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ mb: 2 }}
                    >
                        Back
                    </Button>
                </Link>

                <Typography variant="h4" gutterBottom>
                    Training Due
                </Typography>

                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rows={safeData}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10 }
                            }
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        rowHeight={85}
                        onRowClick={handleRowClick}
                        sx={{
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#f5f5f5',
                                borderBottom: '1px solid #ccc',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: '1px solid #eee',
                                display: 'flex',
                                alignItems: 'center',
                                p: 1,
                                lineHeight: '1.3',
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                            },
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                                backgroundColor: '#fafafa',
                            },
                        }}
                    />
                </Box>
            </Paper>
        </Box>
    );
}

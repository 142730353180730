// src/components/trainingDue/TrainingDueDefaultForm.jsx

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

// Импорт компонентов
import TrainingDueInfo from '../TrainingDueInfo';
import TrainingDueUploadActions from '../TrainingDueUploadActions';
import TrainingDueEmployees from '../TrainingDueEmployees';
import TrainingDueEmployeeSelectionModal from '../TrainingDueEmployeeSelectionModal';
import TrainingDueSignModal from '../TrainingDueSignModal';
import TrainingDueTextModal from '../TrainingDueTextModal';

// Импорт сервисов и констант
import {
    getTrainingDueById,
    saveEmployeeSignature,
    updateTrainingDue,
    uploadTrainingDueSignatures,
} from '../../../services/trainingDueService';
import { initializeDB } from '../../../config/db';
import {
    TRAINING_DUE_FORM_STORE,
    EMPLOYEE_STORE,
} from '../../../config/constants';

/**
 * Загружает название курса и тексты на английском/испанском по fieldTrainingMasterId
 */
async function loadCourseTextAndName(fieldTrainingMasterId) {
    if (!fieldTrainingMasterId) {
        return { courseName: '', textEnglish: '', textSpanish: '' };
    }

    const db = await initializeDB();
    const tx = db.transaction('trainingDueText', 'readonly');
    const store = tx.objectStore('trainingDueText');
    const textRecord = await store.get(fieldTrainingMasterId);

    if (!textRecord) {
        return {
            courseName: '',
            textEnglish: 'No records for this course.',
            textSpanish: 'No records for this course.',
        };
    }

    return {
        courseName: textRecord.course || '',
        textEnglish: textRecord.textEnglish || 'No records for this course.',
        textSpanish: textRecord.textSpanish || 'No records for this course.',
    };
}

export default function TrainingDueDefaultForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const { trainingDueId } = location.state || {};

    // Локальные состояния
    const [trainingDueRecord, setTrainingDueRecord] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [openTextModal, setOpenTextModal] = useState(false);
    const [textContent, setTextContent] = useState('');
    const [openSignModal, setOpenSignModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeSelectionModalOpen, setEmployeeSelectionModalOpen] = useState(false);
    const [lastSentAt, setLastSentAt] = useState('');
    const sigPadRef = useRef(null);

    /**
     * Загружает данные TrainingDue, подгружает текст курса и обновляет запись в хранилище
     */
    const loadTrainingDueData = useCallback(async (id) => {
        try {
            let record = await getTrainingDueById(id);
            if (!record) {
                console.error('TrainingDue record not found.');
                return;
            }

            // Если дата не установлена – ставим текущую
            if (!record.date) {
                record.date = new Date().toISOString();
            }

            // Подгружаем текст курса
            const { courseName, textEnglish, textSpanish } = await loadCourseTextAndName(record.fieldTrainingMasterId);
            record.courseName = courseName || record.course || '';
            record.textEnglish = textEnglish;
            record.textSpanish = textSpanish;

            // Инициализируем список подписавшихся сотрудников
            record.signedEmployees = record.signedEmployees || [];
            if (record.fullName) {
                const foundEmp = await findEmployeeByFullName(record.fullName);
                if (foundEmp && !record.signedEmployees.some(e => e.id === foundEmp.id)) {
                    record.signedEmployees.push({ ...foundEmp, signature: null, signedAt: null });
                }
            }

            // Сохраняем обновлённую запись в TRAINING_DUE_FORM_STORE
            const db = await initializeDB();
            const tx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
            const store = tx.objectStore(TRAINING_DUE_FORM_STORE);
            await store.put(record);

            // Обновляем состояние
            setTrainingDueRecord(record);
            setEmployees(record.signedEmployees);
            setLastSentAt(record.lastSentAt || '');
        } catch (error) {
            console.error("Error loading TrainingDue data:", error);
        }
    }, []);

    /**
     * Ищет сотрудника по полному имени в EMPLOYEE_STORE
     */
    const findEmployeeByFullName = useCallback(async (fullName) => {
        const db = await initializeDB();
        const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
        const store = tx.objectStore(EMPLOYEE_STORE);
        const allEmployees = await store.getAll();
        return allEmployees.find(e => e.FullName === fullName);
    }, []);

    // При загрузке компонента или изменении trainingDueId – загружаем данные
    useEffect(() => {
        if (!trainingDueId) {
            navigate('/training-due');
            return;
        }
        loadTrainingDueData(trainingDueId);
    }, [trainingDueId, navigate, loadTrainingDueData]);

    /**
     * Открывает модальное окно для просмотра текста (английский/испанский)
     */
    const handleOpenTextModal = useCallback((language) => {
        if (!trainingDueRecord) return;
        const content =
            language === 'english'
                ? trainingDueRecord.textEnglish || 'No records for this course.'
                : trainingDueRecord.textSpanish || 'No records for this course.';
        setTextContent(content);
        setOpenTextModal(true);
    }, [trainingDueRecord]);

    /**
     * Открывает модалку для подписи сотрудника
     */
    const handleOpenSignModal = useCallback((employeeId) => {
        setSelectedEmployee(employeeId);
        setOpenSignModal(true);
    }, []);

    /**
     * Сохраняет подпись сотрудника
     */
    const handleSaveSignature = useCallback(async () => {
        if (sigPadRef.current && trainingDueRecord && selectedEmployee) {
            const signatureData = sigPadRef.current.toDataURL();
            try {
                const updatedRecord = await saveEmployeeSignature(
                    trainingDueRecord.id,
                    selectedEmployee,
                    signatureData
                );
                setTrainingDueRecord(updatedRecord);
                setEmployees(updatedRecord.signedEmployees || []);
            } catch (error) {
                console.error('Error saving signature:', error);
                alert('Failed to save signature.');
            }
            setOpenSignModal(false);
        }
    }, [selectedEmployee, trainingDueRecord]);

    /**
     * Загружает (upload) подписи на сервер
     */
    const handleUploadSignatures = useCallback(async () => {
        if (!trainingDueRecord) return;
        try {
            const payload = {
                trainingDueId: trainingDueRecord.id,
                date: trainingDueRecord.date || new Date().toISOString(),
                signatures: (trainingDueRecord.signedEmployees || []).map(emp => ({
                    employeeId: emp.id,
                    fullName: emp.FullName,
                    signature: emp.signature,
                    signedAt: emp.signedAt,
                })),
            };

            const response = await uploadTrainingDueSignatures(payload);
            if (response.status === 200) {
                const updatedRecord = {
                    ...trainingDueRecord,
                    status: 'sent',
                    lastSentAt: new Date().toISOString(),
                };
                await updateTrainingDue(updatedRecord);
                setTrainingDueRecord(updatedRecord);
                setEmployees(updatedRecord.signedEmployees || []);
                setLastSentAt(updatedRecord.lastSentAt);
                alert('Data sent successfully');
                navigate('/training-due');
            }
        } catch (error) {
            console.error("Error uploading signatures:", error);
            alert("Failed to upload signatures.");
        }
    }, [trainingDueRecord, navigate]);

    /**
     * Обновляет список сотрудников через модальное окно выбора
     */
    const handleSaveEmployees = useCallback(async (updatedEmployeeIds) => {
        if (!trainingDueRecord) return;
        try {
            const db = await initializeDB();
            const tx = db.transaction(EMPLOYEE_STORE, 'readonly');
            const store = tx.objectStore(EMPLOYEE_STORE);
            const existingSigned = trainingDueRecord.signedEmployees || [];
            const newSigned = [];

            for (const empId of updatedEmployeeIds) {
                const existing = existingSigned.find(e => e.id === empId);
                if (existing) {
                    newSigned.push({ ...existing });
                } else {
                    const empData = await store.get(empId);
                    if (empData) {
                        newSigned.push({ ...empData, signature: null, signedAt: null });
                    }
                }
            }

            const updatedRecord = { ...trainingDueRecord, signedEmployees: newSigned };
            if (updatedRecord.status === 'new' && newSigned.length > 0) {
                updatedRecord.status = 'process';
            }

            const updateTx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
            const updateStore = updateTx.objectStore(TRAINING_DUE_FORM_STORE);
            await updateStore.put(updatedRecord);

            setTrainingDueRecord(updatedRecord);
            setEmployees(newSigned);
        } catch (error) {
            console.error("Error saving updated employees:", error);
        }
    }, [trainingDueRecord]);

    /**
     * Удаляет сотрудника из списка
     */
    const handleDeleteEmployee = useCallback(async (employeeId) => {
        if (!trainingDueRecord) return;
        try {
            const db = await initializeDB();
            const tx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
            const store = tx.objectStore(TRAINING_DUE_FORM_STORE);
            const updatedRecord = {
                ...trainingDueRecord,
                signedEmployees: (trainingDueRecord.signedEmployees || []).filter(e => e.id !== employeeId),
            };
            await store.put(updatedRecord);
            setTrainingDueRecord(updatedRecord);
            setEmployees(updatedRecord.signedEmployees);
            alert('Employee deleted successfully');
        } catch (error) {
            console.error("Error deleting employee:", error);
            alert("Failed to delete employee.");
        }
    }, [trainingDueRecord]);

    // Если данные ещё не загружены – показываем индикатор загрузки
    if (!trainingDueRecord) {
        return <p>Loading...</p>;
    }

    const selectedEmployeeName =
        employees.find(emp => emp.id === selectedEmployee)?.FullName || 'Unknown';

    return (
        <Box sx={{ p: 4 }}>
            <Link to="/training-due">
                <Button variant="contained" color="success" sx={{ my: 2 }}>
                    Back
                </Button>
            </Link>

            <TrainingDueInfo
                trainingDueRecord={trainingDueRecord}
                onViewEnglish={() => handleOpenTextModal('english')}
                onViewSpanish={() => handleOpenTextModal('spanish')}
            />

            {employees.length > 0 && (
                <TrainingDueUploadActions
                    onUpload={handleUploadSignatures}
                    lastSentAt={lastSentAt}
                    status={trainingDueRecord.status}
                />
            )}

            <Typography variant="h5" sx={{ mt: 3 }}>
                Employees List:
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => setEmployeeSelectionModalOpen(true)}
            >
                Edit Employees List For This Training
            </Button>

            <TrainingDueEmployees
                employees={employees}
                recordStatus={trainingDueRecord.status}
                onDeleteEmployee={handleDeleteEmployee}
                onOpenSignModal={handleOpenSignModal}
            />

            <TrainingDueEmployeeSelectionModal
                open={employeeSelectionModalOpen}
                onClose={() => setEmployeeSelectionModalOpen(false)}
                onSave={handleSaveEmployees}
                existingEmployees={employees.map(e => e.id)}
            />

            <TrainingDueTextModal
                open={openTextModal}
                onClose={() => setOpenTextModal(false)}
                textContent={textContent}
            />

            <TrainingDueSignModal
                open={openSignModal}
                onClose={() => setOpenSignModal(false)}
                sigPadRef={sigPadRef}
                employeeName={selectedEmployeeName}
                onAccept={handleSaveSignature}
            />
        </Box>
    );
}

import React from 'react';
import { Container, Grid } from '@mui/material';
import { useMainPageStatuses } from '../../hooks/useMainPageStatuse';
import { option } from './Option';
import { CardOption } from './CardOption';

export default function MainPage() {
    const { tbtStatuses, ccStatus } = useMainPageStatuses();

    const rawRoles = localStorage.getItem('roles');
    let userRoles = [];

    if (rawRoles) {
        try {
            const parsed = JSON.parse(rawRoles);
            userRoles = Array.isArray(parsed) ? parsed : [parsed];
        } catch {
            userRoles = [rawRoles];
        }
    }

    const visibleOptions = option.filter(item => {
        if (!item.roles) return true;
        return item.roles.some(r => userRoles.includes(r));
    });

    return (
        <Container maxWidth={false} sx={{ py: 3, width: '100%' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {visibleOptions.map((opt) => (
                    <CardOption
                        key={opt.name}
                        name={opt.name}
                        icon={opt.icon}
                        link={opt.link}
                        tbtStatuses={tbtStatuses}
                        ccStatus={ccStatus}
                    />
                ))}
            </Grid>
        </Container>
    );
}

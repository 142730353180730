// src/services/trainingDueService.js

import axiosInstance from '../axiosInstance';
import { initializeDB } from '../config/db';

// Наши ObjectStores
import {
    TRAINING_DUE_FORM_STORE,
    TRAINING_DUE_STORE,
    STATUS_NEW,
    STATUS_PROCESS,
    STATUS_FINISH,
} from '../config/constants';

/**
 * Загрузить/инициализировать запись TrainingDue для работы.
 * 1) Сначала ищем в TRAINING_DUE_FORM_STORE (может быть уже инициировано).
 * 2) Если нет — ищем в TRAINING_DUE_STORE (исходные данные).
 *    - Если там нашли, инициализируем запись в TRAINING_DUE_FORM_STORE
 *      (добавляем поля: signedEmployees = [], status = 'new', lastSentAt = null и т.п.)
 * 3) Если не нашли ни там, ни там — возвращаем null.
 */
export async function getTrainingDueById(id) {
    try {
        const db = await initializeDB();

        // 1) Ищем в TRAINING_DUE_FORM_STORE
        const formTx = db.transaction(TRAINING_DUE_FORM_STORE, 'readonly');
        const formStore = formTx.objectStore(TRAINING_DUE_FORM_STORE);
        let record = await formStore.get(id);

        if (record) {
            // Уже инициализировано, возвращаем
            return record;
        }

        // 2) Ищем в TRAINING_DUE_STORE (исходник)
        const sourceTx = db.transaction(TRAINING_DUE_STORE, 'readonly');
        const sourceStore = sourceTx.objectStore(TRAINING_DUE_STORE);
        const sourceRecord = await sourceStore.get(id);

        if (!sourceRecord) {
            // Нет ни в TRAINING_DUE_FORM_STORE, ни в TRAINING_DUE_STORE
            return null;
        }

        // Если нашли в TRAINING_DUE_STORE, инициализируем запись в TRAINING_DUE_FORM_STORE
        // Добавляем поля, необходимые для подписей и статусов
        const newRecord = {
            id: sourceRecord.id,
            course: sourceRecord.course || '',
            fullName: sourceRecord.fullName || '',
            division: sourceRecord.division || '',
            dateDue: sourceRecord.dateDue || null,
            fieldTrainingMasterId: sourceRecord.fieldTrainingMasterId || null,

            // Новые поля, чтобы вести учёт подписей
            signedEmployees: [],
            status: STATUS_NEW,    // или 'process', как удобнее
            lastSentAt: null
        };

        const writeTx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
        const writeStore = writeTx.objectStore(TRAINING_DUE_FORM_STORE);
        await writeStore.put(newRecord);

        return newRecord;
    } catch (error) {
        console.error("Error in getTrainingDueById:", error);
        return null;
    }
}

/**
 * Сохранить подпись сотрудника (в TRAINING_DUE_FORM_STORE).
 * trainingDueId: ID записи в TRAINING_DUE_FORM_STORE
 * employeeId: айди сотрудника
 * signatureData: base64
 */
export async function saveEmployeeSignature(trainingDueId, employeeId, signatureData) {
    try {
        const db = await initializeDB();
        const tx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
        const store = tx.objectStore(TRAINING_DUE_FORM_STORE);

        const record = await store.get(trainingDueId);
        if (!record) {
            throw new Error(`No trainingDue record found in store with id=${trainingDueId}`);
        }

        // Ищем сотрудника в signedEmployees
        const updatedEmployees = [...(record.signedEmployees || [])];
        const index = updatedEmployees.findIndex(emp => emp.id === employeeId);
        if (index >= 0) {
            // Обновляем подпись
            updatedEmployees[index].signature = signatureData;
            updatedEmployees[index].signedAt = new Date().toISOString();
        } else {
            // Теоретически, если сотрудника ещё нет, можем добавить
            updatedEmployees.push({
                id: employeeId,
                FullName: 'Unknown user', // или подтягивайте откуда-то
                signature: signatureData,
                signedAt: new Date().toISOString()
            });
        }

        // Меняем статус, если был 'new'
        if (record.status === STATUS_NEW) {
            record.status = STATUS_PROCESS;
        }

        record.signedEmployees = updatedEmployees;
        await store.put(record);

        return record;
    } catch (error) {
        console.error("Error in saveEmployeeSignature:", error);
        throw error;
    }
}

/**
 * Обновить объект TrainingDue (напр. статус) в TRAINING_DUE_FORM_STORE.
 */
export async function updateTrainingDue(updatedRecord) {
    try {
        const db = await initializeDB();
        const tx = db.transaction(TRAINING_DUE_FORM_STORE, 'readwrite');
        const store = tx.objectStore(TRAINING_DUE_FORM_STORE);

        await store.put(updatedRecord);
        return updatedRecord;
    } catch (error) {
        console.error("Error in updateTrainingDue:", error);
        throw error;
    }
}

/**
 * Пример отправки подписей на сервер (POST) — адаптируйте под ваш эндпоинт.
 */
export async function uploadTrainingDueSignatures(payload) {
    // Допустим, отправляем POST на /training-due-upload
    // В payload отправляются: trainingDueId, signatures, date, ...
    return axiosInstance.post('/training-due-upload', payload);
}

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export default function SelectEmployeeModal({
                                                open,
                                                onClose,
                                                employees = [],
                                                onSelect,
                                                title = 'Select Employee',
                                                defaultSelectedName = ''
                                            }) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (open) {
            // Пример: сортируем алфавитом.
            const sorted = [...employees].sort((a, b) =>
                (a.FullName || '').localeCompare(b.FullName || '')
            );
            setRows(sorted);
        }
    }, [open, employees]);

    // Определяем, кто сейчас выбран, чтобы подсветить.
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (!open) return;
        // Найдём сотрудника по defaultSelectedName
        const found = rows.find(r => r.FullName === defaultSelectedName);
        if (found) {
            setSelectionModel([found.id]);
        } else {
            setSelectionModel([]);
        }
    }, [open, rows, defaultSelectedName]);

    const columns = [
        { field: 'Division', headerName: 'Division', width: 100 },
        { field: 'FullName', headerName: 'Full Name', flex: 1 },
    ];

    // Клик по строке
    const handleRowClick = (params) => {
        const selectedEmp = rows.find(r => r.id === params.id);
        onSelect(selectedEmp);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick={false}
                        // false, чтобы можно было кликать по строке
                        rowSelectionModel={selectionModel}
                        checkboxSelection={false}
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectionModel(newSelection);
                        }}
                        // При клике на строку
                        onRowClick={handleRowClick}
                        pagination
                        pageSizeOptions={[25, 50, 100]}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

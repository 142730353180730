// src/components/trainingDue/TrainingDueSignModal.jsx
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import SignaturePad from 'react-signature-canvas';

export default function TrainingDueSignModal({
                                                 open,
                                                 onClose,
                                                 sigPadRef,
                                                 employeeName,
                                                 onAccept
                                             }) {
    const clearSignature = () => {
        if (sigPadRef.current) {
            sigPadRef.current.clear();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                maxWidth: 600,
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                p: 3,
                boxShadow: 24,
                borderRadius: '8px',
            }}>
                <Typography variant="h6" gutterBottom>
                    Signature for: {employeeName}
                </Typography>

                <Box
                    sx={{
                        border: '1px solid #ccc',
                        mb: 2
                    }}
                >
                    <SignaturePad
                        ref={sigPadRef}
                        canvasProps={{
                            width: 560,
                            height: 200,
                            style: { display: 'block' }
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button variant="outlined" onClick={clearSignature}>
                        Clear
                    </Button>
                    <Button variant="contained" onClick={onAccept}>
                        Accept
                    </Button>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

// src/services/mepFormService.js
import { initializeDB } from '../config/db';
import {
    MEP_FORM_STORE,
    STATUS_PROCESS,
    STATUS_SEND
} from '../config/constants';

/**
 * Получить из IndexedDB первую форму со статусом "process"
 */
export async function getProcessMepForm() {
    const db = await initializeDB();
    const tx = db.transaction(MEP_FORM_STORE, 'readonly');
    const store = tx.objectStore(MEP_FORM_STORE);

    const allForms = await store.getAll();
    await tx.done;

    // Ищем первую, у которой status === 'process'
    return allForms.find((f) => f.status === STATUS_PROCESS) || null;
}

/**
 * Сохранить/обновить MEP-форму
 */
export async function saveMepForm(formData) {
    const db = await initializeDB();
    const tx = db.transaction(MEP_FORM_STORE, 'readwrite');
    const store = tx.objectStore(MEP_FORM_STORE);

    const dataToSave = {
        ...formData,
        status: formData.status || STATUS_PROCESS
    };

    // put создаёт или обновляет запись
    const newId = await store.put(dataToSave);
    await tx.done;

    return newId;
}

/**
 * Пометить форму "отправленной" (status = 'send')
 */
export async function markMepFormSend(formId) {
    const db = await initializeDB();
    const tx = db.transaction(MEP_FORM_STORE, 'readwrite');
    const store = tx.objectStore(MEP_FORM_STORE);

    const existing = await store.get(formId);
    if (!existing) {
        await tx.done;
        return;
    }
    existing.status = STATUS_SEND;
    await store.put(existing);
    await tx.done;
}

export async function getAllForms() {
    const db = await initializeDB();
    const tx = db.transaction(MEP_FORM_STORE, 'readonly');
    const store = tx.objectStore(MEP_FORM_STORE);
    const all = await store.getAll();
    await tx.done;
    return all;
}